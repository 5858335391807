<template>
  <div class="content">
    <h1>Tax ID Validation</h1>

    <p>
      The <b>Tax ID Pro</b> API makes it easy to validate tax ID numbers for
      <a href="#countries">over 100 countries</a>. The API returns JSON-encoded responses,
      and uses standard HTTP response codes.
    </p>
    <br />

    <h5>Sample API request</h5>
    <p>
      Below is a request for a German individual tax ID number (Steuernummer). There are
      several ways to authorize your request, read our
      <router-link :to="{ name: 'Authorization' }">authorization guide</router-link> to
      learn more.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      https://api.taxid.pro/validate<br />
      <div style="padding-left: 18px">
        ? country = <span class="has-text-warning">de</span><br />
        &amp; tin = <span class="has-text-warning">365742618098</span><br />
        &amp; type = <span class="has-text-warning">individual</span><br />
      </div>
    </div>

    <br />

    <h5>Sample API responses</h5>
    <p>
      In this case, the number submitted was too short. The message adapts to provide
      specific guidance where available.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "valid": <span class="has-text-danger">false</span>,<br />
        "message": "Steuernummer should have 13 digits."
      </div>
      }
    </div>

    <p>When the result is valid, the message is an empty string.</p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "valid": <span class="has-text-success">true</span>,<br />
        "message": ""
      </div>
      }
    </div>

    <p>
      As long as the request itself is properly formatted, the response will always be
      returned with a 200 status code, even if the tax ID number is not valid. If there is
      an issue with the request itself (for example, a missing country code) a 4XX or 5XX
      status code will be returned.
    </p>

    <br />

    <h5>Query parameters</h5>

    <p>
      At a minimum, the query should include a
      <span class="value">tin</span> and a <span class="value">country</span>. Including a
      <span class="value">type</span>
      parameter is recommended if you know what type of tax ID number you need.
    </p>
    <hr />

    <p>
      <span class="param"
        >country
        <span class="param-note">required, unless irsCountry is provided</span></span
      >
      A two-letter country code, as defined in the
      <a href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes">
        ISO standard</a
      >.
    </p>

    <hr />

    <p>
      <span class="param">irsCountry <span class="param-note">optional</span></span>
      Optionally, it may be more convenient to provide the
      <a
        href="https://www.irs.gov/e-file-providers/foreign-country-code-listing-for-modernized-e-file"
      >
        IRS standard</a
      >
      country code.
    </p>

    <hr />

    <p>
      <span class="param">tin <span class="param-note">required</span></span>
      The tax ID Number. It may contain numbers, letters (uppercase or lowercase) dashes,
      or underscores.
    </p>

    <hr />

    <p>
      <span class="param">type <span class="param-note">optional</span></span>
      Specify <span class="value">individual</span>, <span class="value">entity</span>, or
      <span class="value">vat</span>. Exclude this parameter to test all available types
      for a given country. Some caveats to keep in mind if you do not specify a type:
    </p>
    <ul>
      <li>The result will be valid if <b>any</b> format is valid.</li>
      <li>The result will only be invalid if <b>all</b> formats are invalid.</li>
      <li>Guidance provided in the response may be less specific.</li>
    </ul>
    <p>
      For example, there are three types of tax ID numbers in Germany, one for
      individuals, one for entities, and one for vat numbers. If your tax ID number is a
      valid Steuernummer, and you do not specify a type, the validator will test all three
      formats available, and the response will be
      <span class="value">valid: true</span> because it was valid for one of the three
      types tested. We recommend experimenting with this setting to ensure you are getting
      the behavior you are expecting.
    </p>

    <hr />

    <p>
      <span class="param">unavailable <span class="param-note">optional</span></span>
      Specify <span class="value">invalid</span>, <span class="value">valid</span>, or
      <span class="value">null</span>.
    </p>

    <p>
      Some countries, such as Bermuda, do not issue tax ID numbers. Other countries may 
      have obscure tax ID formats that aren't available for testing. The unavailable
      parameter determines the behavior in these situations. We recommend experimenting
      with this setting to ensure you are receiving the response you are expecting.
    </p>

    <p>
      If the format is not available for testing, the default setting is
      <span class="value">invalid</span>, which will return
      <span class="value">valid: false</span>. The default setting is a safeguard against
      false validations, but it may be too strict for your use case. Consider the
      <a href="#countries">countries and formats available</a>, and whether one of the
      other options is a better fit.
    </p>

    <p>
      Set unavailable to <span class="value">valid</span> if you would like unavailable
      formats to return <span class="value">valid: true</span>. You may want to use this
      approach in situations where you don't want to impede a user sign-up or onboarding
      process because the user has an obscure or unavailable tax ID format.
    </p>

    <p>
      The <span class="value">null</span> setting will return
      <span class="value">valid: null</span> when a format is unavailable, which is
      helpful if you would like some indication that no testing was performed, but you
      want to design some special handling for this scenario. For example, you may want to
      allow your user to continue but also flag your user for additional verification if
      the result is <span class="value">null</span>.
    </p>

    <hr />

    <p>
      <span class="param">version <span class="param-note">optional</span></span>
      The default version is based on your
      <router-link :to="{ name: 'Developer' }"> settings</router-link>. If you specify
      version <span class="value">1</span> or <span class="value">2</span> as a query
      parameter, the specified version will override your account settings. Note that
      version 1 is deprecated, however there is currently no timetable for removing it.
    </p>

    <hr />

    <p>
      <span class="param">key <span class="param-note">optional</span></span>
      Your API key. Learn how to
      <router-link :to="{ name: 'Authorization' }">authorize</router-link> your API
      requests, or
      <router-link :to="{ name: 'Signup' }">get your API key here</router-link>.
    </p>

    <hr />

    <h3 id="countries">Validator Availability by Country</h3>

    <p>
      The following is a list of countries currently supported by our tax ID validation
      API. Testing falls into two categories: basic or advanced. The <b>basic</b> test
      checks for the correct sequence of letters and numbers. The <b>advanced</b> test
      uses checksum algorithms to further test for validity.
    </p>
    <p>
      If you would like to request support for additional countries, please
      <router-link :to="{ name: 'Contact' }">contact us</router-link>!
    </p>

    <br />

    <!-- TODO update this list -->

    <div class="box">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Country</th>
            <th width="20%">Individual</th>
            <th width="20%">Entity</th>
            <th width="20%">VAT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Albania</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Andorra</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Argentina</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Armenia</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Aruba</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Australia</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Austria</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Azerbaijan</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Bangladesh</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Barbados</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Belarus</td>
            <td>Basic</td>
            <td>Advanced</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Belgium</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Belize</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Bolivia</td>
            <td>Basic</td>
            <td></td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Brazil</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Brunei</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Bulgaria</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Canada</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Chile</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>China</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Colombia</td>
            <td>Basic</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Costa Rica</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Croatia</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Cuba</td>
            <td>Advanced</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Curacao</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Cyprus</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Czech Republic</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Denmark</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Dominican Rep.</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Ecuador</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Egypt</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>El Salvador</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Estonia</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Faroe Islands</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Finland</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>France</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Georgia</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Germany</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Gibraltar</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Greece</td>
            <td>Basic</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Greenland</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Guatemala</td>
            <td>Basic</td>
            <td>Advanced</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Guernsey</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Hong Kong</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Hungary</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Iceland</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>India</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Indonesia</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Ireland</td>
            <td>Advanced</td>
            <td>Basic</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Israel</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td></td>
          </tr>
          <tr>
            <td>Italy</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Jamaica</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Japan</td>
            <td>Basic</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Jersey</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Kazakhstan</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Kuwait</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Kyrgyzstan</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Latvia</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Liechtenstein</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Lithuania</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Luxembourg</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Malaysia</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Malta</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Mauritius</td>
            <td>Advanced</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Mexico</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td></td>
          </tr>
          <tr>
            <td>Moldova</td>
            <td>Basic</td>
            <td>Advanced</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Monaco</td>
            <td></td>
            <td></td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Morocco</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Netherlands</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>New Zealand</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Nicaragua</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Norway</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Pakistan</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Paraguay</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Peru</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Philippines</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Poland</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Portugal</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Romania</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Russia</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Samoa</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>San Marino</td>
            <td>Basic</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Serbia</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Slovakia</td>
            <td>Advanced</td>
            <td>Basic</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Slovenia</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>South Africa</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>South Korea</td>
            <td>Advanced</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Spain</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Sri Lanka</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Sweden</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Switzerland</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Tajikistan</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Thailand</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Trinidad</td>
            <td>Basic</td>
            <td>Basic</td>
            <td></td>
          </tr>
          <tr>
            <td>Tunisia</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Turkey</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>Turkmenistan</td>
            <td>Basic</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Ukraine</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>United Kingdom</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
          <tr>
            <td>United States</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td></td>
          </tr>
          <tr>
            <td>Uruguay</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td></td>
          </tr>
          <tr>
            <td>Uzbekistan</td>
            <td>Basic</td>
            <td>Basic</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Venezuela</td>
            <td>Advanced</td>
            <td>Advanced</td>
            <td>Advanced</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.param {
  font-family: monospace;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
.param-note {
  font-family: open-sans, sans-serif;
  font-weight: bold;
  color: #aaa;
  font-size: 0.75rem;
}
.value {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f5f5f5;
  font-size: 0.75rem;
  padding: 2px 4px 1px;
  font-family: monospace;
}
</style>

<script>
export default {
  name: 'Validation',
  mounted() {
    if (this.$route.hash) {
      window.location.hash = this.$route.hash
    } else {
      window.scrollTo(0, 0)
    }
  },
}
</script>